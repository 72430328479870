import { render, staticRenderFns } from "./GeneeaTagsListView.vue?vue&type=template&id=136263c6&scoped=true&"
import script from "./GeneeaTagsListView.vue?vue&type=script&lang=js&"
export * from "./GeneeaTagsListView.vue?vue&type=script&lang=js&"
import style0 from "./GeneeaTagsListView.vue?vue&type=style&index=0&id=136263c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "136263c6",
  null
  
)

export default component.exports